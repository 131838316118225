'use client';
import { ReactNode } from 'react';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import cx from 'classnames';

import { IconName, Icons, isIconName } from '../Icons';
import { IconAsset, IconAssetName, isIconAssetName } from '../IconAssets';

import styles from './RadioButton.module.scss';

type RadioItem = {
  value: string;
  label: ReactNode;
  helperText?: string;
  icon?: IconName | IconAssetName;
  iconSize?: number;
  iconHasFrame?: boolean;
};

type RadioButtonProps = RadixRadioGroup.RadioGroupItemProps & {
  position?: 'left' | 'right';
} & RadioItem;

export function RadioButton({
  className,
  value,
  label,
  helperText,
  id,
  icon,
  position = 'left',
  disabled,
  iconSize,
  iconHasFrame = false,
  ...props
}: RadioButtonProps) {
  return (
    <div className={cx(styles.radioGroupItemContainer, className)} key={value}>
      {position === 'left' && (
        <LabeledInfo helperText={helperText} icon={icon} htmlFor={id} iconSize={iconSize} iconHasFrame={iconHasFrame}>
          {label}
        </LabeledInfo>
      )}

      <RadixRadioGroup.Item
        {...props}
        className={cx(styles.radioGroupItem, {
          [styles.disabled]: disabled,
        })}
        id={id}
        disabled={disabled}
        value={value}
      >
        <RadixRadioGroup.Indicator className={styles.radioGroupIndicator} />
      </RadixRadioGroup.Item>

      {position === 'right' && (
        <LabeledInfo helperText={helperText} icon={icon} iconSize={iconSize} iconHasFrame={iconHasFrame} htmlFor={id}>
          {label}
        </LabeledInfo>
      )}
    </div>
  );
}

function LabeledInfo({
  helperText,
  children,
  icon,
  htmlFor,
  iconSize = 50,
  iconHasFrame = false,
}: {
  children: ReactNode;
  helperText?: string;
  icon?: IconName | IconAssetName;
  htmlFor?: string;
  iconSize?: number;
  iconHasFrame?: boolean;
}) {
  let IconComponent = null;
  if (isIconName(icon)) {
    IconComponent = Icons[icon];
  } else if (isIconAssetName(icon)) {
    IconComponent = IconAsset[icon];
  }

  return (
    <label className={styles.labeledInfoContainer} htmlFor={htmlFor}>
      <div>
        {IconComponent ? (
          iconHasFrame ? (
            <div className={styles.iconFrame}>
              <IconComponent width={iconSize} height={iconSize} />
            </div>
          ) : (
            <IconComponent width={iconSize} height={iconSize} />
          )
        ) : null}
      </div>
      <div className={styles.labelWithIcon}>
        {children}
        {helperText && <div className={styles.helperText}>{helperText}</div>}
      </div>
    </label>
  );
}
